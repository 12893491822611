import * as React from 'react';
import { isMobile } from 'react-device-detect';
//@ts-ignore
import LogoImage from './logo.svg';
//@ts-ignore
import LogoImageWhite from './logo_white.svg';

const whiteLogoPages = [
  '/retouch',
  '/digital-art',
  '/product-photography',
  '/nature-photography',
];

const Logo = () => {
  const size = isMobile ? 72 : 124;
  return typeof window !== 'undefined' &&
    whiteLogoPages.includes(window.location.pathname) ? (
    <LogoImageWhite style={{ width: '100%' }} height={size} width={size} />
  ) : (
    <LogoImage style={{ width: '100%' }} height={size} width={size} />
  );
};

export default Logo;
