/** @jsx jsx */
import { jsx, Link, useThemeUI } from 'theme-ui';
import { readableColor } from 'polished';
import useSiteMetadata from '@lekoarts/gatsby-theme-jodie/src/hooks/use-site-metadata';
import SocialIcons from './SocialIcons';

const Footer = ({ bg }: { bg: string }) => {
  const { siteTitle } = useSiteMetadata();
  const {
    theme: { rawColors },
  } = useThemeUI();

  const text = readableColor(
    bg,
    rawColors!.textMuted as string | undefined,
    rawColors!.textMutedLight as string | undefined
  );

  return (
    <footer
      sx={{
        position: [`relative`, `relative`, `relative`, `fixed`],
        width: (t: any): any => [
          `100%`,
          `100%`,
          `100%`,
          t.sidebar.normal,
          t.sidebar.wide,
        ],
        bottom: 0,
        color: text,
        fontSize: 0,
        p: [3, 3, 4],
        background: bg,
        a: {
          color: readableColor(bg),
          '&:hover,&:focus': {
            color: readableColor(bg, `primary`, `primaryLight`, false),
          },
        },
        variant: `footer`,
      }}>
      <SocialIcons />
      <div style={{ marginBottom: 16 }}>
        &copy; {new Date().getFullYear()} by {siteTitle}.
      </div>
      <div>
        <Link
          aria-label="Link to the theme's GitHub repository"
          href='https://github.com/LekoArts/gatsby-themes/tree/master/themes/gatsby-theme-jodie'>
          Theme
        </Link>
        {` `}
        by
        {` `}
        <Link
          aria-label="Link to the theme author's website"
          href='https://www.lekoarts.de?utm_source=jodie&utm_medium=Theme'>
          LekoArts
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
