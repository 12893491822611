import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInstagram,
  faPinterestP,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';

export interface SocialIconsProps {}

export default function SocialIcons(props: SocialIconsProps) {
  return (
    <div
      className='icons'
      style={{
        zIndex: 10,
      }}>
      <ul
        style={{
          padding: '20px 0',
          width: '100%',
          listStyle: `none`,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
        }}>
        <li>
          <a href='https://www.instagram.com/plantsfollowers/' target='_blank'>
            <FontAwesomeIcon size='lg' icon={faInstagram} />
          </a>
        </li>
        <li>
          <a
            href='https://pl.pinterest.com/martynaczernickicom/'
            target='_blank'>
            <FontAwesomeIcon size='lg' icon={faPinterestP} />
          </a>
        </li>
        <li>
          <a
            href='https://www.linkedin.com/in/martyna-czernicki-146024156'
            target='_blank'>
            <FontAwesomeIcon size='lg' icon={faLinkedin} />
          </a>
        </li>
        <li>
          <a
            style={{
              textDecoration: 'none',
              fontSize: '18px',
            }}
            href='/blog'>
            blog
          </a>
        </li>
      </ul>
    </div>
  );
}
